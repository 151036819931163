<template>
    <div class="devices container">
        <div>
            <div class="ui simple middle aligned computer only tablet only one column grid">
                <div class="column">
                    <h3 class="ui header">
                        Device Control
                        <div class="sub header">Manage registered devices on system</div>
                    </h3>
                </div>
            </div>
            <div class="ui simple middle aligned mobile only grid">
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="!mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div>
                                    <h3 class="ui header">
                                        Device Control
                                        <div class="sub header">Manage registered devices on system</div>
                                    </h3>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="icon link" @click="mobile_search = true;">
                                        <i class="search icon"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div style="flex: 1; min-width: 0;">
                                    <form class="ui search form" @submit.prevent="search_">
                                        <div class="ui icon fluid input" :class="{loading}">
                                            <input type="text" placeholder="Device ID, device info..." v-model.trim.lazy="query"/>
                                            <i class="search link icon"></i>
                                        </div>
                                    </form>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="close icon link" @click="mobile_search = false;">
                                        &times;
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="ui hidden divider"></div>
        <div>
            <div class="ui two column stackable simple grid">
                <div class="tablet only computer only column">
                    <form class="ui form" @submit.prevent="search_">
                        <div class="ui left icon action fluid input" :class="{loading}">
                            <i class="search icon"></i>
                            <input type="text" placeholder="Device ID, device info..." v-model.trim.lazy="query"/>
                            <button type="submit" class="ui icon black button">
                                <i class="arrow right icon"></i>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="right aligned column">
                    <div class="ui compact secondary menu">
                        <div ref="sortbydropdown" class="ui inline dropdown item">
                            <input type="hidden" :value="sorting.by" @change="changeSorting"/>
                            <span style="margin-right: 0.5em;">Sort By:</span><div class="text">Default</div>
                            <i class="dropdown icon"></i>
                            <div class="menu">
                                <div class="item" data-value="id">ID</div>
                                <div class="item" data-value="created">Registration Date / Time</div>
                            </div>
                        </div>
                        <button class="ui fitted item button" type="button" title="Ascending" @click="sortAsc"><i class="sort amount up icon" :class="{teal: sorting.order === 'asc'}"></i></button>
                        <button class="ui fitted item button" type="button" title="Descending" @click="sortDesc"><i class="sort amount down icon" :class="{teal: sorting.order === 'desc'}"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-container">
            <div class="ui hidden divider"></div>
            <div class="ui very basic segment" :class="{loading}">
                <div class="ui secondary teal menu">
                    <a href="javascript:void(0);" class="item" :class="{active: verified === null}" @click="verified = null;">All</a>
                    <a href="javascript:void(0);" class="item" :class="{active: verified === true}" @click="verified = true;">Approved</a>
                    <a href="javascript:void(0);" class="item" :class="{active: verified === false}" @click="verified = false;">Pending/Revoked</a>
                </div>
                <table class="ui very basic unstackable responsive table">
                    <thead>
                        <tr>
                            <th>Device</th>
                            <th>Last Activity</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <template v-if="devices.length">
                        <transition-group tag="tbody" name="vue-server-side-paginated-list" mode="out-in">
                            <tr v-for="device in devices" :key="`device_tr_${device.id}`">
                                <td data-title="Device">
                                    <strong><pre class="device-id">{{device.id}}</pre></strong>
                                    <div>
                                        <i class="icon" :class="device.info|browsericon"></i>
                                        <i class="icon" :class="device.info|osicon"></i>
                                        <label class="ui small label">{{device.info|deviceinfo}}</label>
                                    </div>
                                </td>
                                <td data-title="Last Activity">
                                    {{device.updated_by_first_name}} {{device.updated_by_last_name}}
                                    <div class="meta" v-if="device.updated">
                                        {{device.updated|formatdate("DD MMM YYYY hh:mmA")}}
                                    </div>
                                    <label class="ui small label">{{device.ip}}</label>
                                </td>
                                <td data-title="Status">
                                    <toggle-button v-model="device.verified" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" :tag="device.id" @change="toggleStatus" sync/>
                                </td>
                                <td class="right aligned">
                                    <div class="ui text compact action menu">
                                        <div class="item">
                                            <button class="ui circular icon alt red button" type="button" title="Remove Device" @click="removeDevice(device)"><i class="trash alternate icon"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </transition-group>
                    </template>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">No device registered.</td>
                        </tr>
                    </tbody>
                    <tfoot v-show="devices.length">
                        <tr>
                            <th colspan="4" class="right aligned">
                                <pagination-menu ref="paginationmenu" :total-count="pagination.total_count" :start-page="pagination.page" :page-size="pagination.size" @page-changed="changePage" always-show/>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from "lodash.debounce";

import PaginationMenu from "@/components/PaginationMenu";
import { ToggleButton } from "vue-js-toggle-button";

export default {
    name: "devices",
    components: {
        PaginationMenu,
        ToggleButton
    },
    filters: {
        browsericon(info) {
            if(!info || !info.client) {
                return "";
            }

            let browsericon = "";
            switch(info.client.family) {
                case "Chrome":
                    browsericon = "chrome";
                    break;

                case "Firefox":
                    browsericon = "firefox browser";
                    break;

                case "Internet Explorer":
                    if(info.client.name === "Microsoft Edge") {
                        browsericon = "edge";
                    } else {
                        browsericon = "internet explorer";
                    }

                    break;

                case "Opera":
                    browsericon = "opera";
                    break;

                case "Safari":
                    browsericon = "safari";
                    break;

                default:
                    browsericon = "globe";
            }

            return browsericon;
        },
        osicon(info) {
            if(!info || !info.os) {
                return "";
            }

            let osicon = "";
            switch(info.os.family) {
                case "Android":
                    osicon = "android";
                    break;

                case "Chrome OS":
                    osicon = "chrome";
                    break;

                case "Firefox OS":
                    osicon = "firefox";
                    break;

                case "iOS":
                case "Mac":
                    osicon = "apple";
                    break;

                case "GNU/Linux":
                    osicon = "linux";
                    break;

                case "Windows":
                case "Windows Mobile":
                    osicon = "windows";
                    break;
            }

            return osicon;
        },
        deviceinfo(info) {
            if(!info) {
                return "";
            }

            let device_info = "";
            device_info = `${info?.client?.name} on ${info?.device?.type}`;
            if(info?.device?.brand || info?.device?.model) {
                device_info += ` (${info.device.brand} ${info.device.model})`;
            }

            return device_info.trim();
        }
    },
    data() {
        return {
            mobile_search: false,
            loading: true,
            query: "",
            devices: [],
            sorting: {
                by: "created",
                order: "desc"
            },
            verified: null,
            pagination: {
                total_count: 0,
                page: 1,
                size: 10
            },
            toggle_checkbox: {
                color: "#0cc2da",
                width: 36,
                height: 20,
                labels: {
                    checked: " ",
                    unchecked: " "
                }
            },
            scrollY: window.scrollY || 0,
            search_: debounce(this.search, 100)
        };
    },
    created() {
        this.search_();
    },
    mounted() {
        $(this.$refs.sortbydropdown).dropdown();
    },
    activated() {
        this.search().finally(() => {
            if(this.scrollY) {
                this.$nextTick(() => {
                    window.scroll(0, this.scrollY);
                });
            };
        });
    },
    beforeRouteLeave(to, from, next) {
        this.scrollY = window.scrollY;
        next();
    },
    computed: {
        search_params() {
            let search_params = {
                page: this.pagination.page,
                size: this.pagination.size,
                sort: this.sorting.by,
                order: this.sorting.order,
                fetch_user: true
            };

            if(this.verified !== null) {
                search_params.filter = JSON.stringify({
                    verified: this.verified
                });
            }

            if(this.query) {
                search_params.search = JSON.stringify({
                    id: this.query,
                    info: this.query
                });
            }

            return search_params;
        }
    },
    methods: {
        search() {
            this.loading = true;

            const params = this.search_params;
            return this.$http.get("devices", {
                params
            }).then((response) => {
                this.devices = response.data;

                const total_count = response.headers["x-total-count"];
                this.pagination.total_count = total_count? +total_count : this.devices.length;
            }).catch((error) => {
                console.error(error);

                this.$toasted.error("Unable to list registered devices. Please try again later.", {
                    duration: 3000
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        changeSorting(event) {
            this.sorting.by = event.target.value;
        },
        sortAsc() {
            this.sorting.order = "asc";
        },
        sortDesc() {
            this.sorting.order = "desc";
        },
        changePage(page) {
            this.pagination.page = page;
        },
        toggleStatus({ srcEvent = {}, tag = "", value }) {
            if(tag) {
                const device = this.devices.find((device) => {
                    return device.id === tag;
                });

                if(device) {
                    this.$confirm({
                        title: `${value? "Approve" : "Reject"} Device`,
                        message: `Are you sure you want to ${value? "approve" : "reject"} device <strong>${device.id}</strong>?`,
                        button: {
                            yes: "Yes",
                            no: "Cancel"
                        },
                        callback: (confirmed) => {
                            if(confirmed) {
                                const param = {
                                    verified: value
                                };

                                if(value) {
                                    param.verified_by = this._user.id;
                                }

                                this.$http.put(`devices/${device.id}`, param).then((response) => {
                                    this.$toasted.success(`Device ${value? "approved" : "rejected"} successfully.`, {
                                        duration: 3000
                                    });

                                    this.search_();
                                }).catch((error) => {
                                    device.verified = !value;
                                    console.error(error);

                                    this.$toasted.error(`Failed to ${value? "approve" : "reject"} device.`, {
                                        duration: 3000
                                    });
                                });
                            } else {
                                device.verified = !value;
                            }
                        }
                    });
                }
            }
        },
        removeDevice(device) {
            if(this.isAdmin) {
                this.$confirm({
                    title: "Remove Device",
                    message: `Are you sure you want to remove device <strong>${device.id}</strong>?`,
                    button: {
                        yes: "Yes",
                        no: "Cancel"
                    },
                    callback: (confirmed) => {
                        if(confirmed) {
                            this.$http.delete(`devices/${device.id}`).then((response) => {
                                this.$toasted.success(`Device ${device.id} removed successfully.`, {
                                    duration: 3000
                                });

                                this.search_();
                            }).catch((error) => {
                                console.error(error);

                                this.$toasted.error(`Failed to remove device ${device.id}.`, {
                                    duration: 3000
                                });
                            });
                        }
                    }
                });
            }
        }
    },
    watch: {
        search_params: {
            deep: true,
            handler(search_params, _search_params) {
                if(search_params.search !== _search_params.search || search_params.filter !== _search_params.filter) {
                    if(this.pagination.page === 1) {
                        this.search_();
                    } else {
                        this.$refs.paginationmenu.stepTo(1);
                    }
                } else {
                    this.search_();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.devices.container {
    background: #fff;
}

.meta {
    margin-top: 0.25em;
    color: rgba(0,0,0,.4);
    font-size: 0.95em;
}

.ui.mobile.only.grid {
    position: relative;
}

.mobile.action.links {
    display: inline-flex;
    align-items: flex-start;

    .icon.link {
        font-size: 1.2rem;
        padding: 0.35rem;
        display: inline-block;

        &.plus, &.close {
            font-size: 2.25rem;
        }

        .icon {
            margin: 0;
        }
    }
}

pre.device-id {
    margin-top: 0;
}

.ui.action.menu {
    .ui.dropdown {
        .menu {
            > .active.item,
            > .selected.item {
                font-weight: 400 !important;
                color: rgba(0,0,0,.87)!important;

                &:not(:hover) {
                    background: none !important;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        min-height: 0;
    }
}

@media only screen and (max-width: 767px) {
    .ui.table.responsive.unstackable {
        tbody {
            tr {
                position: relative;

                td:first-child {
                    padding-right: 2em; //to give way for the action menu
                }

                .ui.action.menu {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    .list-container {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-bottom: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #fbf7f2;/*#fafff8;*/
        border-radius: 20px 20px 0 0;
    }
}
</style>