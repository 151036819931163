<template>
    <div class="ui pagination text" :class="{'center aligned' : is_sm}" v-show="totalCount && (page_count > 1 || alwaysShow)">
        <div class="ui secondary compact menu">
            <a href="javascript:void(0);" class="icon item" :class="{disabled: page === 1}" @click="prevStep"><i class="left angle icon"></i></a>
            <template v-if="is_sm">
                <div class="item">
                    <select v-model="page" class="ui selection page compact dropdown">
                        <option v-for="n in page_count" :key="`step_option_${n}`" :value="n">{{n}}</option>
                    </select>&nbsp;/ {{page_count}}
                </div>
            </template>
            <template v-else>
                <template v-for="n in page_count">
                    <a href="javascript:void(0);" class="ui item link" :class="{active: n === page, 'disabled icon': isPageDisabled(n)}" :key="`step_${n}`" @click="stepTo(n)" v-if="showStep(n)" v-html="pageStep(n)"></a>
                </template>
            </template>
            <a href="javascript:void(0);" class="icon item" :class="{disabled: page === page_count}" @click="nextStep"><i class="right angle icon"></i></a>
        </div>
    </div>
</template>

<script>
export default {
    name: "pagination-menu",
    props: {
        adjacentStep: {
            type: Number,
            default: 2
        },
        totalCount: {
            type: Number,
            required: true
        },
        startPage: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            required: true
        },
        alwaysShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            page: this.startPage
        };
    },
    computed: {
        page_count() {
            return Math.ceil(this.totalCount / this.pageSize);
        },
        page_adj() {
            let adj = this.adjacentStep;
            if(!this.is_lg) {
                adj = 1;
            }
            return adj;
        },
        max_step() {
            //5 because total step of [first_step, last_step, current_step, leading_truncate, trailing_truncate]
            //2 because of 2 sides of leading and trailing
            return 5 + (2 * this.page_adj);
        }
    },
    methods: {
        stepTo(n) {
            if(!this.isPageDisabled(n)) {
                this.page = n;
            }
        },
        prevStep() {
            if(this.page > 1) {
                this.page--;
            }
        },
        nextStep() {
            if(this.page < this.page_count) {
                this.page++;
            }
        },
        pageStep(n) {
            if(this.isPageDisabled(n)) {
                return "<i class='grey ellipsis horizontal icon'></i>";
            }
            return n;
        },
        isPageDisabled(n) {
            if(this.page_count <= this.max_step) {
                return false;
            } else if(this.page > (this.max_step - 2 - this.page_adj) && n === 2) {
                return true;
            } else if(this.page < (this.page_count - 2 - this.page_adj) && n === this.page_count - 1) {
                return true;
            }
            return false;
        },
        showStep(n) {
            // always show first page
            if(n === 1) {
                return true;
            }

            // always show last page
            if(n === this.page_count) {
                return true;
            }

            // if current page falls within the first max_step - 2, show all previous step
            // always show the first max_step - 2
            // 2 because of (1 ellipsis + 1 final step)
            if(n >= 1 && n <= (this.max_step - 2) && this.page >= 1 && this.page <= (this.max_step - 2 - this.page_adj)) {
                return true;
            }

            // if current page falls within the last max_step - 2, show all next step
            // always show the last max_step - 2
            // 2 because of (1 ellipsis + 1 first step)
            if(n > (this.page_count - (this.max_step - 2)) && n <= this.page_count && this.page > (this.page_count - (this.max_step - 2 - this.page_adj)) && this.page <= this.page_count) {
                return true;
            }

            // always show 2 adjacent pages for desktop, 1 for mobile
            if(Math.abs(this.page - n) <= this.page_adj) {
                return true;
            }

            // show if page is only page between first page and first adjacent page
            // also show if page is the first page of pages between first page and first adjacent page (...)
            if((this.page - n) >= (this.page_adj + 1) && n === 2) {
                return true;
            }

            // show if page is only page between last page and last adjacent page
            // also show if page is the last page of pages between last page and last adjacent page (...)
            if((n - this.page >= (this.page_adj + 1)) && n === (this.page_count - 1)) {
                return true;
            }

            return false;
        }
    },
    watch: {
        page() {
            this.$emit("page-changed", this.page);
        }
    }
};
</script>

<style lang="scss" scoped>
.ui.selection.page.dropdown {
    appearance: none;
    padding: 0 1em;
    margin-right: 0.5em;
}
</style>